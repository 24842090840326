import React, { createContext, useState, useContext, ReactNode } from 'react';
import { INegocio, INegocioCambioRequest, INegocioConsorcioRequest, INegociosGroupResponse } from '../Interface/interfaceNegocios';
import NegociosService from '../Services/NegociosService';
import { enqueueSnackbar } from 'notistack';

interface INegociosContext {
  negocios: INegociosGroupResponse | undefined;
  loading: boolean;
  error: string | null;
  createNegocio: (request: INegocioCambioRequest | INegocioConsorcioRequest) => Promise<void>;
  fetchNegociosByClienteId: (clienteId: string) => Promise<void>;
  updateNegocio: (updates: Partial<INegocio>) => Promise<void>;
  updateNegocioCambio: (data: INegocioCambioRequest) => Promise<void>;
  updateNegocioConsorcio: (data: INegocioConsorcioRequest) => Promise<void>;
}

const NegociosContext = createContext<INegociosContext | undefined>(undefined);

export const NegociosProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [negocios, setNegocios] = useState<INegociosGroupResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const negociosService = new NegociosService();

  const fetchNegociosByClienteId = async (clienteId: string) => {
    setLoading(true);
    setError(null);
    try {
      const result = await negociosService.ListNegociosByClienteId(clienteId);
      setNegocios(result);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createNegocio = async (request: INegocioCambioRequest | INegocioConsorcioRequest) => {
    setError(null);
    try {
      if ('Cambio' in request) {
        await negociosService.CadastrarNegocioCambio(request as INegocioCambioRequest);
      } else if ('Consorcio' in request) {
        await negociosService.CadastrarNegocioConsorcio(request as INegocioConsorcioRequest);
      }
      // Refaz a busca dos negócios do cliente para garantir que a lista esteja atualizada
      await fetchNegociosByClienteId(request.Negocio.ClienteId || '');
      enqueueSnackbar('Negócio criado com sucesso!', { variant: 'success' });
    } catch (err: any) {
      setError(err.message);
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  const updateNegocio = async (updates: Partial<INegocio>) => {
    setLoading(true);
    setError(null);
    try {
      await negociosService.UpdateNegocio(updates);
      if (negocios?.NegociosCambio || negocios?.NegociosConsorcio) {
        const clienteId =
          negocios.NegociosCambio?.find(n => n.Negocio.Id === updates.Id)?.Negocio.ClienteId ||
          negocios.NegociosConsorcio?.find(n => n.Negocio.Id === updates.Id)?.Negocio.ClienteId;
        if (clienteId) {
          await fetchNegociosByClienteId(clienteId);
        }
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateNegocioCambio = async (data: INegocioCambioRequest) => {
    setError(null);
    try {
      await negociosService.UpdateNegocioCambio(data.Cambio.Id ?? '', data.Cambio);
      await negociosService.UpdateNegocio(data.Negocio);

      setNegocios((prev) => {
        if (!prev) return prev;
        const updatedCambioList = prev.NegociosCambio.map((negocio) =>
          negocio.Negocio.Id === data.Negocio.Id ? { ...negocio, Negocio: data.Negocio, Cambio: data.Cambio } : negocio
        );
        return { ...prev, NegociosCambio: updatedCambioList };
      });
      enqueueSnackbar('Negócio de câmbio atualizado com sucesso!', { variant: 'success' });
    } catch (err: any) {
      setError(err.message);
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  const updateNegocioConsorcio = async (data: INegocioConsorcioRequest) => {
    setError(null);
    try {
      await negociosService.UpdateNegocioConsorcio(data.Consorcio.Id ?? '', data.Consorcio);
      await negociosService.UpdateNegocio(data.Negocio);

      setNegocios((prev) => {
        if (!prev) return prev;
        const updatedConsorcioList = prev.NegociosConsorcio.map((negocio) =>
          negocio.Negocio.Id === data.Negocio.Id ? { ...negocio, Negocio: data.Negocio, Consorcio: data.Consorcio } : negocio
        );
        return { ...prev, NegociosConsorcio: updatedConsorcioList };
      });
      enqueueSnackbar('Negócio de consórcio atualizado com sucesso!', { variant: 'success' });
    } catch (err: any) {
      setError(err.message);
      enqueueSnackbar(err.message, { variant: 'error' });
      throw err;
    }
  };

  return (
    <NegociosContext.Provider
      value={{
        negocios,
        loading,
        error,
        createNegocio,
        fetchNegociosByClienteId,
        updateNegocio,
        updateNegocioCambio,
        updateNegocioConsorcio
      }}
    >
      {children}
    </NegociosContext.Provider>
  );
};

export const useNegocios = (): INegociosContext => {
  const context = useContext(NegociosContext);
  if (!context) {
    throw new Error('useNegocios must be used within a NegociosProvider');
  }
  return context;
};
