import React, { useEffect, useState } from "react";
import {
  List, ListItem, ListItemIcon, ListItemText, Divider,
  Collapse, ListItemButton, Button, IconButton, Avatar, Typography
} from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import StorageHelper from "../../../Services/Helpers/StorageHelper";
import { usePermissions } from "../../../Hooks/usePermissionsUser";
import {
  AiOutlineFunnelPlot, BiBarChartSquare, BiTask, MdPersonAddAlt
} from "react-icons/all";
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import SchoolIcon from '@mui/icons-material/School';
import AddCommentIcon from '@mui/icons-material/AddCommentOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { DialogNovoLeadComponent } from "./DialogNovoLeadComponent";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

interface SideBarComponentProps {
  open: boolean;
}

interface Permission {
  PermissionName: string;
}

// Componente para um Item de Menu
const MenuItem: React.FC<{ to: string; icon: JSX.Element; label: string; open: boolean; onClick?: () => void }> = ({ to, icon, label, open, onClick }) => (
  <ListItem sx={{ pl: 2 }} button component={Link} to={to} onClick={onClick}>
    <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center' }}>
      {icon}
    </ListItemIcon>
    <ListItemText
      sx={{ opacity: open ? 1 : 0 }}
      primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>{label}</Typography>}
    />
  </ListItem>
);

// Componente para Links Externos
const ExternalLinkItem: React.FC<{ href: string; icon: JSX.Element; label: string; open: boolean }> = ({ href, icon, label, open }) => (
  <ListItemButton component="a" href={href} target="_blank" rel="noopener noreferrer" sx={{ pl: 2 }}>
    <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "auto", justifyContent: "center" }}>
      {icon}
    </ListItemIcon>
    <ListItemText
      sx={{ opacity: open ? 1 : 0 }}
      primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>{label}</Typography>}
    />
  </ListItemButton>
);

// Componente para Colapsar Seções
const CollapseSection: React.FC<{ icon: JSX.Element; label: string; open: boolean; isOpen: boolean; toggleOpen: () => void }> = ({ icon, label, open, isOpen, toggleOpen, children }) => (
  <>
    <ListItemButton onClick={toggleOpen} sx={{ pl: 2 }}>
      <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "auto", justifyContent: "center" }}>
        {icon}
      </ListItemIcon>
      <ListItemText
        sx={{ opacity: open ? 1 : 0 }}
        primary={<Typography variant="h6" sx={{ color: "#f3f2ff", fontSize: "15px" }}>{label}</Typography>}
      />
      {isOpen ? <ExpandLessIcon color="primary" /> : <ExpandMoreIcon color="primary" />}
    </ListItemButton>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      {children}
    </Collapse>
  </>
);

export const SideBarComponentV2: React.FC<SideBarComponentProps> = ({ open }) => {
  const storageHelper = new StorageHelper();
  const [usuarioRole, setUsuarioRole] = useState<string>("");
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isFunilOpen, setIsFunilOpen] = useState(false);
  const [openDialogNewLead, setOpenDialogNewLead] = useState(false);
  const { permissions } = usePermissions();

  useEffect(() => {
    setUsuarioRole(storageHelper.GetUsuarioRole() || "");
  }, []);

  const toggleAdmin = () => setIsAdminOpen(!isAdminOpen);
  const toggleFunil = () => setIsFunilOpen(!isFunilOpen);

  const Logout = () => {
    storageHelper.Logout();
    return <Navigate to="/" />;
  };

  // Função para verificar permissões
  const hasPermission = (roles: string[]): boolean =>
    roles.some(role => permissions.some((p: Permission) => p.PermissionName === role)) || usuarioRole === "Administrador";

  const showFunilComercialSeguros = hasPermission(["Seguros", "AdminSeguros"]);
  const showFunilComercialCambio = hasPermission(["Câmbio", "AdminCâmbio"]);
  const showFunilComercialConsorcios = hasPermission(["Consórcio", "AdminConsórcio"]);
  const hasTombamentoPermission = permissions.some((p: Permission) => p.PermissionName === "Tombamento");

  return (
    <>
      <List sx={{ color: "red" }}>
        {/* Itens do Menu */}
        <MenuItem to="dashboard-pbi" icon={<BiBarChartSquare style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Dashboard Power BI" open={open} />
        <MenuItem to="atividades" icon={<InsertInvitationIcon style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Atividades" open={open} />
        <MenuItem to="tasks-list" icon={<BiTask style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Tarefas" open={open} />

        <Divider />

        {/* Colapso para Funis Comerciais */}
        <CollapseSection icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Funis Comerciais" open={open} isOpen={isFunilOpen} toggleOpen={toggleFunil}>
          <MenuItem to="kanban" icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Funil Comercial" open={open} />
          <MenuItem to="advisor-kanban" icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Negócios" open={open} />
          {showFunilComercialSeguros && <MenuItem to="kanban-insurance" icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Seguros" open={open} />}
          {showFunilComercialCambio && <MenuItem to="kanban-cambio" icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Câmbio" open={open} />}
          {showFunilComercialConsorcios && <MenuItem to="kanban-consorcios" icon={<AiOutlineFunnelPlot style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Consórcios" open={open} />}
        </CollapseSection>

        <MenuItem to="clientes" icon={<AccountBalanceWalletIcon style={{ height: 23, width: 23, color: "#a5a4bf" }} />} label="Clientes" open={open} />

        <Divider />

        {/* Links Externos */}
        <ExternalLinkItem href="https://uxp.skore.io/pt/login?redirect=%2Fpt%2Fpages%2Fu_marco_investimentos" icon={<SchoolIcon sx={{ color: "#a5a4bf" }} />} label="Formação" open={open} />
        <ExternalLinkItem href="https://app.pipefy.com/portals/32a605dd-a56b-4c55-bbed-c240baebd357" icon={<AddCommentIcon sx={{ color: "#a5a4bf" }} />} label="Solicitações" open={open} />

        {/* Administração */}
        {(usuarioRole === "Administrador" || hasTombamentoPermission) && (
          <CollapseSection icon={<SupervisorAccountIcon sx={{ color: "#a5a4bf" }} />} label="Administração" open={open} isOpen={isAdminOpen} toggleOpen={toggleAdmin}>
            <MenuItem to="admin/powerbi" icon={<BiBarChartSquare style={{ height: 20, width: 20, color: "#a5a4bf" }} />} label="Editar Dashboard PBI" open={open} />
            <MenuItem to="admin/squads" icon={<SupervisorAccountIcon fontSize="small" sx={{ color: "#a5a4bf" }} />} label="Gestão de Squads" open={open} />
            <MenuItem to="admin/user-management" icon={<PersonAddAltIcon fontSize="small" sx={{ color: "#a5a4bf" }} />} label="Gestão de Usuários" open={open} />
            <MenuItem to="admin/upload-xp-files" icon={<DriveFolderUploadIcon fontSize="small" sx={{ color: "#a5a4bf" }} />} label="Upload XP" open={open} />
            {hasTombamentoPermission && <MenuItem to="admin/reassign-clients" icon={<DriveFolderUploadIcon fontSize="small" sx={{ color: "#a5a4bf" }} />} label="Tombamento de Clientes" open={open} />}
          </CollapseSection>
        )}

        {/* Logout */}
        <MenuItem to="/" icon={<LogoutIcon sx={{ color: "#a5a4bf" }} />} label="Sair" open={open} onClick={Logout} />

        {/* Botão de Novo Lead */}
        <ListItem sx={{ justifyContent: "center" }}>
          {open ? (
            <Button onClick={() => setOpenDialogNewLead(true)} startIcon={<MdPersonAddAlt />} variant="contained" color="primary" sx={{ width: 150 }}>
              Novo Lead
            </Button>
          ) : (
            <Avatar variant="rounded" sx={{ bgcolor: "#E24BAC" }}>
              <IconButton onClick={() => setOpenDialogNewLead(true)}>
                <MdPersonAddAlt style={{ color: "#ffffff" }} />
              </IconButton>
            </Avatar>
          )}
        </ListItem>
      </List>

      <DialogNovoLeadComponent open={openDialogNewLead} onClose={() => setOpenDialogNewLead(false)} />
    </>
  );
};
