import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Card, CardContent, Typography, IconButton, Menu, MenuItem, Grid, Box, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { INegocioCambioRequest } from '../../../Interface/interfaceNegocios';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccountOutlined';

interface Props {
  negocio: INegocioCambioRequest;
  index: number;
}

export const KanbanItemCambio: React.FC<Props> = ({ negocio, index }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    const url = `/app/perfil-cliente/${negocio.Negocio.ClienteId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
    handleClose();
  };

  const calculateDaysSinceStart = (startDate: Date) => {
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - new Date(startDate).getTime();
    return Math.floor(timeDiff / (1000 * 3600 * 24));
  };

  const daysSinceStart = calculateDaysSinceStart(negocio.Negocio.DataInicio);

  const moedaAndPaisParceiro = () => {
    if (negocio.Cambio.Moeda && negocio.Cambio.PaisParceiro) {
      return `${negocio.Cambio.Moeda}, ${negocio.Cambio.PaisParceiro}`;
    }

    return negocio.Cambio.PaisParceiro || negocio.Cambio.Moeda;
  }

  return (
    <Draggable draggableId={negocio.Negocio.Id ?? ''} index={index}>
      {(provided) => (
        <Grid item xs={12} mx={2}>
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{ marginBottom: 2, cursor: 'pointer', position: 'relative' }}
          >
            <CardContent>
              <Box display='flex' alignItems='center' mb={1}>
                <PersonIcon style={{ marginRight: 8 }} />
                <Typography variant='h6' fontSize={14}>{negocio.NomeCliente ? negocio.NomeCliente : '-'}</Typography>
              </Box>

              <Box display='flex' alignItems='center' mb={0.5}>
                <Typography variant='body1' color='green' fontWeight='bold'>{negocio.Cambio.ValorReais ? negocio.Cambio.ValorReais.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</Typography>
              </Box>

              <Box display='flex' alignItems='center' mb={0.5}>
                <Typography variant='body1'>{moedaAndPaisParceiro()}</Typography>
              </Box>

              <Tooltip title={negocio.NomeVendedor ? 'Vendedor responsável' : 'Assessor responsável'} disableInteractive>
                <Box display='flex' alignItems='center' mb={0.5}>
                  <SupervisorAccountIcon style={{ marginRight: 8 }} />
                  <Typography variant='body1'>
                    {negocio.NomeVendedor ? negocio.NomeVendedor : negocio.NomeAssessor ?? ''}
                  </Typography>
                </Box>
              </Tooltip>

              {daysSinceStart >= 0 && <Box display='flex' alignItems='center' mb={1} sx={{ position: 'absolute', bottom: 0 }}>
                <Typography variant='body2' color='textSecondary'>
                  {`Dias desde o início: ${daysSinceStart} dias`}
                </Typography>
              </Box>}

              <IconButton
                aria-label='more'
                aria-controls='long-menu'
                aria-haspopup='true'
                onClick={handleClick}
                sx={{ position: 'absolute', right: 8, top: 8 }}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleOpenProfile}>Abrir perfil</MenuItem>
              </Menu>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Draggable>
  );
};