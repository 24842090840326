import './App.scss';
import './Globals.scss';
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import LoginPage from './Components/Pages/Publicas/LoginPage';
import CriarContaPage from './Components/Pages/Publicas/CriarContaPage';
import RecuperarSenhaPage from './Components/Pages/Publicas/RecuperarSenhaPage';
import PlanoDeVooPage from './Components/Pages/Privadas/PlanoDeVooPage';
import PlanoDeVooPageResultado from './Components/Pages/Privadas/OutputPage';
import ClientesPage from './Components/Pages/Privadas/ClientesPage';
import PerfilCliente from './Components/Pages/Privadas/PerfilClientePage'
import NotfoundPage from './Components/Pages/Publicas/NotfoundPage';
import AlterarSenhaPage from './Components/Pages/Publicas/AlterarSenhaPage';
import PrimeiraReuniaoPage from './Components/Pages/Privadas/PrimeiraReuniaoPage';
import StorageHelper from './Services/Helpers/StorageHelper';
import AppPage from "./Components/Pages/Privadas/AppPage"
import { AtividadesPage } from './Components/Pages/Privadas/AtividadesPage';
import { SquadsPage } from './Components/Pages/Privadas/SquadsPage';
import SolicitacoesPage from './Components/Pages/Privadas/SolicitacoesPage';
import { UsersRegistrationPage } from './Components/Pages/Privadas/UsersRegistrationPage';
import TasksListPage from './Components/Pages/Privadas/TasksListPage';
import DashboardPBI from './Components/Pages/Privadas/DashboardPBI';
import DashboardsPBI from './Components/Common/Admin/DashboardsPBI';
import Kanban from './Components/Pages/Privadas/Kanban';
import { UserManagementPage } from './Components/Pages/Privadas/UserManagement';
import { UploadXPFiles } from './Components/Pages/Privadas/UploadXPFiles';
import KanbanInsurance from './Components/Pages/Privadas/KanbanInsurance';
import { ReassignClients } from './Components/Pages/Privadas/ReassignClients';
import KanbanCambio from './Components/Pages/Privadas/KanbanCambio';
import KanbanConsorcios from './Components/Pages/Privadas/KanbanConsorcios';
import { AdvisorKanbanPage } from './Components/Pages/Privadas/AdvisorKanban';

function App() {
  function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();

    let isAuth = false

    const storageHelper = new StorageHelper()
    const dataExpiracao = storageHelper.GetDataExpiracaoToken()
    if (!dataExpiracao) {
      isAuth = false
    } else if (new Date(dataExpiracao) < new Date()) {
      isAuth = false
    }
    else if (dataExpiracao < new Date()) {
      isAuth = false
    } else {
      isAuth = true
    }

    if (!isAuth) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
  }

  return (
    <>

      <Routes>
        <Route element={<LoginPage />} path="/" />
        <Route
          path="/app"
          element={
            <RequireAuth>
              <AppPage />
            </RequireAuth>
          }
        >
          {/* <Route path="dashboard" element={<DashboardPage />} /> */}
          <Route path="atividades" element={<AtividadesPage />} />
          <Route path="perfil-cliente/:userId" element={<PerfilCliente />} />
          <Route path="clientes" element={<ClientesPage />} />
          <Route path="tasks-list" element={<TasksListPage />} />
          <Route path="primeira-reuniao/:userId" element={<PrimeiraReuniaoPage />} />
          <Route path="plano-de-voo-resultado/:userId" element={<PlanoDeVooPageResultado />} />
          <Route path="plano-de-voo" element={<PlanoDeVooPage />} />
          <Route path="plano-de-voo/:userId" element={<PlanoDeVooPage />} />
          <Route path="plano-de-voo/:userId" element={<PlanoDeVooPage />} />
          <Route path="/app/solicitacoes" element={<SolicitacoesPage />} />
          <Route path="admin/squads" element={<SquadsPage />} />
          <Route path="admin/powerbi" element={<DashboardsPBI />} />
          <Route path="admin/users-registration" element={<UsersRegistrationPage />} />
          <Route path="dashboard-pbi" element={<DashboardPBI />} />
          <Route path="kanban" element={<Kanban />} />
          <Route path="kanban-insurance" element={<KanbanInsurance />} />
          <Route path="kanban-cambio" element={<KanbanCambio />} />
          <Route path="kanban-consorcios" element={<KanbanConsorcios />} />
          <Route path="advisor-kanban" element={<AdvisorKanbanPage />} />
          <Route path="admin/user-management" element={<UserManagementPage />} />
          <Route path="admin/upload-xp-files" element={<UploadXPFiles />} />
          <Route path="admin/reassign-clients" element={<ReassignClients />} />
        </Route>
        <Route element={<CriarContaPage />} path="/criar-conta" />
        <Route element={<RecuperarSenhaPage />} path="/recuperar-senha" />
        <Route element={<AlterarSenhaPage />} path="/alterar-senha/:codigo" />
        <Route
          path="*"
          element={<NotfoundPage />}
        />
      </Routes>
    </>
  );
}

export default App;
